import type { AppProps } from 'next/app'
import localFont from 'next/font/local'
import { ToastContainer } from 'react-toastify'
import { useMedia } from 'react-use'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import { registerServiceWorker } from '../utilities/registerServiceWorker'

const SFPro = localFont({
	src: [
		{
			path: '../../public/fonts/sfprodisplay-regular-webfont.woff2',
			weight: '400',
			style: 'normal',
		},
		{
			path: '../../public/fonts/sfprodisplay-bold-webfont.woff2',
			weight: '700',
			style: 'normal',
		},
	],
})

registerServiceWorker()

function MyApp({ Component, pageProps }: AppProps) {
	const theme = useMedia('(prefers-color-scheme: dark)', false) ? 'dark' : 'light'

	return (
		<>
			<style jsx global>{`
				html {
					font-family: ${SFPro.style.fontFamily};
				}
			`}</style>
			<SharedLoadingIndicatorContextProvider>
				<PageNavigationLoadingTracker />
				<SharedProgressLoadingIndicator />
				<ToastContainer theme={theme} />
				<Component {...pageProps} />
			</SharedLoadingIndicatorContextProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
